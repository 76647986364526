import "./style.css";
import React from "react";
import FormInput from "./Components/Forminput";
import { useState } from "react";

function App() {
  const [values, setValues] = useState({
    username: "",
    email: "",
    RefId: "",
    Issue: "",
    description: "",
  });

  const [Issue, setIssue] = useState("");
  const [submissions, setSubmissions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  //const usernameRef = useRef();

  const inputs = [
    {
      id: 1,
      name: "username",
      type: "text",
      placeholder: "Username",
      errorMessage:
        "Username should be 3-16 characters and should'nt incluse any special character!",
      label: "Username",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 3,
      name: "RefId",
      type: "text",
      placeholder: "Ref ID",
      errorMessage: "",
      label: "Ref ID",
    },
    {
      id: 4,
      name: "Issue",
      type: "select",
      label: "Issue",
      options: ["Laptop", "Desktop"],
      errorMessage: "Please select an issue",
      required: true,
    },
    {
      id: 5,
      name: "description",
      type: "textarea", // Define the type as textarea
      placeholder: "Description",
      errorMessage: "Please provide a description",
      label: null,
      row: 4,
      required: false,
    },
    // {
    //   id: 4,
    //   name: "password",
    //   type: "password",
    //   placeholder: "Password",
    //   errorMessage:
    //     "Password should be 8 to 20 characters and should include at least 1 letter , 1 number and 1 special character",
    //   label: "Password",
    //   pattern:
    //     "^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$",
    //   required: true,
    // },
    // {
    //   id: 5,
    //   name: "confirmPassword",
    //   type: "password",
    //   placeholder: "Confirm Password",
    //   errorMessage: "Password don't match",
    //   label: "Confirm Password",
    //   pattern: values.password,
    //   required: true,
    // },
  ];

  function onChange(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });

    setIssue(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    // Include the Issue state in the values object
    const updatedValues = { ...values, Issue: Issue };
    setSubmissions([...submissions, updatedValues]);
    setModalOpen(true);

    // Handle form submission with updated values
    console.log("Form values:", updatedValues);
    //alert("Your request has been logged");
  }
  console.log(values);
  //console.log("Selected Issue:", Issue);
  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <h1>Request</h1>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            values={values[input.name]}
            onChange={onChange}
          />
        ))}
        <button className="show-modal">Submit</button>
      </form>

      {modalOpen && (
        <div className="overlay" onClick={() => setModalOpen(false)}>
          <div className="modal">
            <button className="close-modal" onClick={() => setModalOpen(false)}>
              &times;
            </button>
            <h1>Confirmation</h1>
            <p>Your request is logged</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
