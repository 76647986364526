import { useState } from "react";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import "./formInput.css";

export default function FormInput(props) {
  const [focused, setFocused] = useState(false);

  const {
    label,
    errorMessage,
    onChange,
    id,
    type,
    options,
    values,
    ...inputProps
  } = props;

  function handleFocus(e) {
    setFocused(true);
  }

  return (
    <div className="formInput">
      <FormControl variant="outlined" fullWidth>
        <InputLabel>{label}</InputLabel>
        {type === "select" ? (
          <Select
            {...inputProps}
            value={values || ""}
            label={label}
            error={errorMessage && !values}
            onFocus={handleFocus}
            onChange={onChange}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        ) : type === "textarea" ? (
          <TextField
            multiline
            rows={4} // Set the number of rows as needed
            {...inputProps}
            error={!!errorMessage}
            label={label}
            placeholder="Description"
            onFocus={handleFocus}
            variant="standard"
            onChange={onChange}
          />
        ) : (
          <OutlinedInput
            {...inputProps}
            onChange={onChange}
            label={label}
            onFocus={handleFocus}
          />
        )}
      </FormControl>
      {errorMessage && !values && (
        <span className="error-message">{errorMessage}</span>
      )}
    </div>
  );
}
